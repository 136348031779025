<template>
    <div class="">
        <ol class="stepper" v-if="values">
            <li v-for="(item, idx) in items" :key="item.title">
                <div class="item">
                    <div class="content" v-b-popover.hover.top="getDate(values[item.value + 'FillingDate'])">
                        <span class="step" :class="{ active: values[item.value] }">
                            <i class="fas fa-check active" v-if="values[item.value]"></i>
                            <i class="fas fa-circle" v-else></i>
                        </span>
                        <h4>{{ item.text }}</h4>
                    </div>
                    <span class="line" v-if="idx < items.length - 1"></span>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        // items: {
        //     type: Array,
        // }
    },
    data() {
        return {
            items: [
                {
                    text: 'Main',
                    value: 'main',
                },
                {
                    text: 'Additional Details',
                    value: 'additionalDetails',
                },
                {
                    text: 'MemberCheck',
                    value: 'membercheck',
                },
                {
                    text: 'Screening',
                    value: 'screening',
                },
                {
                    text: 'Job Verification',
                    value: 'jobVerification',
                },
                {
                    text: 'Approval',
                    value: 'approval',
                },
                {
                    text: 'Signed',
                    value: 'signed',
                },
                {
                    text: 'Transferred Funds',
                    value: 'transferredFunds',
                },
            ],
        };
    },
    methods: {
        getDate(item) {
            if (item) {
                return item.split('T')[0];
            }
            return 'Not Available';
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
        }),
        values() {
            if (this.process) {
                return {
                    ...this.process,
                    approval: this.process.approvalLvl1 && this.process.approvalLvl1_2 && this.process.approvalLvl2,
                };
            } else {
                return null;
            }
        },
    },
};
</script>

<style>
.stepper {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    margin-bottom: 0px;
}

.stepper li {
    display: flex;
}

.item {
    display: flex;
    font-size: 14px;
}

.item .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38px;
}

.step {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #f5f5f5;
    display: grid;
    place-content: center;
    margin: 0 4px;
}

.step.active {
    background-color: #cbf6df;
}

.step .active {
    color: #3fb578;
}

.step i {
    color: #cccccc;
}

.line {
    display: block;
    width: 50px;
    height: 2px;
    margin-top: 12px;
    background-color: #e0e0e0;
}

.stepper i {
    font-size: 8px;
}

.stepper h4 {
    font-size: 9px;
    margin-bottom: 0;
    margin-top: 4px;
    text-align: center;
    white-space: nowrap;
}

.stepper p {
    font-size: 12px;
    margin-bottom: 0;
}

/* @media (max-width: 768px) {
    .stepper {
        flex-direction: column;
        align-items: center;
        margin: 0 0;
    }
    .item {
        align-items: center;
    }
    .line {
        width: 2px;
        height: 36px;
        margin-top: 0;
        display: block;
    }
    .content {
        flex-direction: column;
        align-items: center;
    }
} */
</style>
